import * as React from "react"
import { CatalogRef, NPKCatalogs } from "@smartdevis/server/src/domain"
import { H3, P } from "@smartdevis/ui/src/Typography"
import { FlexItem, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { StyledForm } from "../../../components/forms"
import { useRefFunction } from "../../../hooks/utilityHooks"
import { i18n } from "../../../services/translations"
import { buildNPKOrder, splitNPKAttributes } from "../../../utils/catalogs"
import { useDeepEffect } from "@smartdevis/ui/src/hooks/common"
import { useFormHook } from "@smartdevis/forms/src"
import { unique } from "@smartdevis/utils/src/array"
import { TMap, keys } from "@smartdevis/utils/src/map"
import { isEqual } from "@smartdevis/utils/src/misc"
import { isOk } from "@smartdevis/utils/src/result"
import { TypedOmit, F0, F1, Optionalize } from "@smartdevis/utils/src/types"
import { IdLite } from "@smartdevis/utils/src/id"
import { EncodedKey } from "@smartdevis/server/src/domainCatalog"
import { CatalogFormContainer } from "../devis-catalogs/devis-catalogs-styles"
import { CustomAttributesForm, PresetNPKAttributesForm, useNPKAttributesUpdates } from "../devis-catalogs/devis-catalogs-attributes"
import { CatalogFormAttributeType, CatalogItem, CatalogPickerProps } from "../devis-catalogs/devis-catalogs"

const Placeholder = () => (
    <CatalogFormContainer direction="column" xAlign="center" yAlign="center" style={{ padding: "20px" }}>
        <H3>{i18n("Work details form")}</H3>
        <P textAlign="center">{i18n("A place for the details of selected work position")}</P>
    </CatalogFormContainer>
)

type ConnectedCatalogFormProps<FormPayload, Item extends CatalogItem> = TypedOmit<
    CatalogPickerProps<FormPayload, Item>,
    "visible" | "onClose" | "onSubmit" | "onRemove"
> & {
    catalogRef?: CatalogRef | null
    catalogId?: IdLite
    onBrowseCatalog: F0
    onCopy: F0
    isEditing: boolean
    noChanges?: boolean
    onUpdate: F1<Item>
    onAddToList: F1<Item>
    catalogWorkTitle: NPKCatalogs.NPKNode
}

export const NPKCatalogDetailsForm = <FormPayload, Item extends CatalogItem>(
    p: ConnectedCatalogFormProps<FormPayload, Item>
) => {
    if (!p.catalogRef || !p.catalogId) return <Placeholder />
    return <NPKCatalogItemForm {...p} workTitle={p.catalogWorkTitle} />
}

const buildNPKOrderWithCustomAttributes = (wt: NPKCatalogs.NPKNode, attrs: TMap<EncodedKey, EncodedKey[]>) =>
    unique([...buildNPKOrder(wt.subNodes.npknode), ...keys(attrs)])

type CatalogItemFormProps<FormPayload, Item extends CatalogItem> = Optionalize<
    TypedOmit<ConnectedCatalogFormProps<FormPayload, Item>, "shelfVersion" | "catalogId" | "catalogRef">,
    "onBrowseCatalog"
> & { previewMode?: true; workTitle: NPKCatalogs.NPKNode }
const NPKCatalogItemForm = <FormPayload, Item extends CatalogItem>(p: CatalogItemFormProps<FormPayload, Item>) => {
    const [attributes, setAttributes] = React.useState(p.item.catalogRef?.attributes || {})
    const [attributesRerenderFlag, setRerenderAttributesFlag] = React.useState(false)
    const rerenderAttributes = () => setRerenderAttributesFlag(f => !f)

    const { attrs } = splitNPKAttributes(p.workTitle, attributes)

    console.warn('attrs',attrs);
    console.warn('p',p);

    const schema = p.mkSchema(p.item)

    const mkPayload = (delta: Partial<Item> = {}) => ({
        ...p.item,
        ...delta,
        catalogRef: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...p.item.catalogRef!,
            attributes,
            order: buildNPKOrderWithCustomAttributes(p.workTitle, attributes)
        }
    })

    const { formViewProps, result, resetState } = useFormHook({
        schema,
        initialValue: p.mkInitial(p.item),
        onSubmit: formValues => p.onAddToList(mkPayload(formValues))
    })
    React.useEffect(() => {
        resetState()
        setAttributes(p.item.catalogRef?.attributes || {})
        rerenderAttributes()
    }, [p.getItemId(p.item)])

    useDeepEffect({ result, attributes }, () => {
        if (isOk(result)) {
            const payload = mkPayload(result.value)
            if (!isEqual(p.item, payload)) p.onUpdate(payload)
        }
    })

    const { initialCustomAttributes, setCustomAttributes, setPresetAttributes } = useNPKAttributesUpdates({
        selectedWorkTitle: p.workTitle,
        attrs,
        initialValues: attributes,
        onChange: setAttributes
    })

    const customRenderer = useRefFunction(
        (params: { value: CatalogFormAttributeType }) => {
            switch (params.value) {
                case "preset":
                    return (
                        <PresetNPKAttributesForm
                            selectedWorkTitle={p.workTitle}
                            attrs={attrs}
                            initialValues={attributes}
                            onChange={setPresetAttributes}
                        />
                    )
                case "custom":
                    return (
                        <CustomAttributesForm
                            initialAttributes={initialCustomAttributes}
                            onChange={setCustomAttributes}
                        />
                    )
            }
        },
        [attributesRerenderFlag]
    )
    return (
        <CatalogFormContainer overflow="hidden" direction="column">
            <VerticalSpace base="16px" />
            <FlexItem
                overflow="scroll"
                direction="column"
                yAlign="flex-start"
                style={{ display: "block", marginRight: "-3px", paddingRight: "4px" }}>
                <StyledForm
                    {...formViewProps}
                    styledInputsRenderMap={{ Custom: customRenderer.current as any }}
                    styledSchema={p.mkStyledSchema()}
                />
            </FlexItem>
            {/* <FlexRow justifyEnd alignCenter>
                {!p.previewMode && (
                    <>
                        <ButtonV2 onClick={p.onCopy} disabled={!p.noChanges || !p.isEditing}>
                            {i18n("Duplicate")}
                        </ButtonV2>
                        <HorizontalSpace base="8px" />
                        <ButtonV2 disabled={isErr(result) || p.noChanges} onClick={handleSubmit}>
                            {p.isEditing ? i18n("Update >>") : i18n("Save >>")}
                        </ButtonV2>
                    </>
                )}
            </FlexRow> */}
        </CatalogFormContainer>
    )
}
