import * as React from "react"

import { useDebounce } from "@smartdevis/ui/src/hooks/useDebounce"
import { useNullableState } from "../../../hooks/utilityHooks"
import { asyncConnect } from "../../../resolvers"
import { i18n } from "../../../services/translations"
import { IconButton, Button } from "@smartdevis/ui/src/Button"
import { H4, P, H5 } from "@smartdevis/ui/src/Typography"
import { FlexItem, VerticalSpace, FlexColumn, FlexRow, HorizontalSpace } from "@smartdevis/ui/src/utils/common"
import { Join } from "@smartdevis/ui/src/utils/Join"
import { Input } from "@smartdevis/ui/src/Inputs"
import { Collapsable } from "@smartdevis/ui/src/Collapsable"
import { Empty } from "@smartdevis/ui/src/Empty"
import { CatalogRef, NPKCatalogs } from "@smartdevis/server/src/domain"
import { values } from "@smartdevis/utils/src/map"
import { isDefined } from "@smartdevis/utils/src/misc"
import { F1, F0, F2 } from "@smartdevis/utils/src/types"
import { IdLite } from "@smartdevis/utils/src/id"
import { ShelfType, Version } from "@smartdevis/server/src/domainCatalog"
import { Spinner } from "@smartdevis/ui/src/Spinner"
import { CatalogCollapsableItem } from "../devis-catalogs/devis-catalogs-styles"
import { CatalogPickerMode } from "../devis-catalogs/devis-catalogs"
import { LicenseUnlockRequestPayload } from "../../../store/crbNpkCatalogsState"
import { DropDownHandler } from "../npk-catalogs/npk-catalogs-language"

type CatalogSearchProps = {
    shelfType: ShelfType
    onBrowseCatalog: F1<IdLite>
    onBrowseShelf: F0
    pickerMode: CatalogPickerMode
    setPickerMode: F1<CatalogPickerMode>
    onSelect: F2<IdLite, NPKCatalogs.NPKNode>
    shelfVersion: Version
    licenseUnlockDetails: LicenseUnlockRequestPayload
    language: NPKCatalogs.TLanguage
    versionYear: number
    setLanguage: F1<NPKCatalogs.TLanguage>
    setVersionYear: F1<number>
    selectedCatalogRef?: CatalogRef | null

}

export const NPKCatalogSearch = asyncConnect({
    stateResolvers: ["NPKLanguages", "NPKChapters", "NPKVersionYears"],
    actions: ["fetchNPKLanguages", "fetchNPKVersionYears"]
})<CatalogSearchProps>(p => {

    const [chapters, setChapters] = React.useState<NPKCatalogs.Chapters>([]);

    React.useEffect(() => {
        const fetchData = () => {
            if (p.NPKLanguages.type === "NotFetched") {
                p.fetchNPKLanguages();
            }
            if (p.NPKVersionYears.type === "NotFetched") {
                p.fetchNPKVersionYears();
            }
        };
        fetchData();
    }, [p.NPKLanguages, p.NPKVersionYears]);

    const [query, setQuery] = useNullableState<string>()
    const search = useDebounce(
        (text: string) => isDefined(text) ? chapters.filter(e => e.display.includes(text)) : [],
        500
    )

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const q = e.target.value
        setQuery(q)
        search.run(q)
    }

    const renderBrowser = () => {
        const showChildren = !query || p.pickerMode.force
        if (!showChildren) return <CatalogSearchResults chapters={chapters} startedSearch={query !== null} {...p} />

        switch (p.pickerMode.type) {
            case "browseShelf":
                return (
                    <ShelfBrowser
                        shelfType={p.shelfType}
                        shelfVersion={p.shelfVersion}
                        licenseDetails={p.licenseUnlockDetails}
                        language={p.language}
                        onCatalogSelect={catalogId => {
                            p.setPickerMode({ type: "browseCatalog", catalogId })
                        }}
                        setNPKChapters={setChapters}
                    />
                )
            case "browseCatalog":
                return (
                    <CatalogBrowser
                        shelfType={p.shelfType}
                        shelfVersion={p.shelfVersion}
                        onSelect={p.onSelect}
                        selectedCatalogRef={p.selectedCatalogRef}
                        onBack={() => p.setPickerMode({ type: "browseShelf" })}
                        catalogId={p.pickerMode.catalogId}
                        language={p.language}
                        versionYear={p.versionYear.toString()}
                    />
                )
        }
    }
    return (
        <FlexItem overflow="hidden" direction="column">
            <FlexRow>
                <DropDownHandler currentValue={p.language} results={p.NPKLanguages.type === "Fetched" ? p.NPKLanguages.value : []} setValue={(value) => p.setLanguage(value)} />
                <DropDownHandler currentValue={p.versionYear} results={p.NPKVersionYears.type === "Fetched" ? p.NPKVersionYears.value : []} setValue={(value) => p.setVersionYear(value)} />
                <Input
                    placeholder={i18n("Search in catalogs, positions and attributes")}
                    data-cy="positions-search-catalog-input"
                    value={query || ""}
                    onChange={handleChange}
                />
            </FlexRow>
            <VerticalSpace base="16px" />
            <FlexItem overflow="auto" direction="column" yAlign="flex-start">
                {renderBrowser()}
            </FlexItem>
        </FlexItem>
    )
})

type CatalogSearchResultsProps = CatalogSearchProps & { startedSearch: boolean, chapters: NPKCatalogs.Chapters }
const CatalogSearchResults = asyncConnect({ stateResolvers: [], actions: [] })<CatalogSearchResultsProps>(p => {
    const matches = (p.chapters)
    console.warn('matched', matches);
    if (!matches.length)
        return (
            <Empty
                type="node"
                value={
                    <FlexColumn alignCenter>
                        {p.startedSearch ? <H4>{i18n("No results")}</H4> : <H4>{i18n("Catalogs")}</H4>}
                        <P>
                            {i18n("Search for construction work descriptions and add them easily to the devi's.")}
                        </P>
                        <VerticalSpace base="16px" />
                        <IconButton
                            icon="Search"
                            onClick={p.onBrowseShelf}
                            data-cy="positions-search-catalog-browse-all">
                            {i18n("Browse all catalogs")}
                        </IconButton>
                    </FlexColumn>
                }
            />
        )
    return (
        <>
            {matches.map(v => (
                <Collapsable title={v.display} sideText={v.display} key={v.number}>
                    <Join items={values(v.positions)} renderJoining={() => <VerticalSpace base="8px" />}>
                        {vp => (
                            <CatalogCollapsableItem
                                isActive={vp.key === p.selectedCatalogRef?.workTitleKey}
                                onClick={() => p.onSelect(v.number as IdLite, vp)}
                                key={vp.key}>
                                {vp.title}
                            </CatalogCollapsableItem>
                        )}
                    </Join>
                    <VerticalSpace base="8px" />
                    <Button
                        btnType="link"
                        onClick={() => p.onBrowseCatalog(v.number as IdLite)}
                        data-cy="positions-search-catalog-browse-all">
                        {i18n("Browse catalog")}
                    </Button>
                </Collapsable>
            ))}
        </>
    )
})

type CatalogBrowserProps = Pick<CatalogSearchProps, "selectedCatalogRef" | "onSelect" | "shelfType"> & {
    catalogId: IdLite
    shelfVersion: Version,
    versionYear: string,
    language: NPKCatalogs.TLanguage,
    onBack: F0
}
const CatalogBrowser = asyncConnect({ stateResolvers: ["NPKChapterDetails"], actions: ["fetchNPKChapterDetails"] })<CatalogBrowserProps>(p => {
    React.useEffect(() => {
        if (p.NPKChapterDetails.type === "NotFetched") {
            p.fetchNPKChapterDetails({
                chapter: p.catalogId,
                language: p.language,
                year: p.versionYear
            })
        }
    }, [p.NPKChapterDetails])

    React.useEffect(() => {
        p.fetchNPKChapterDetails({
            chapter: p.catalogId,
            language: p.language,
            year: p.versionYear
        })
    }, [p.versionYear])


    switch (p.NPKChapterDetails.type) {
        case "NotFetched": return <></>
        case "Fetching": return <Spinner />
        case "Fetched":
            const chapterDetails = p.NPKChapterDetails.value
            return (
                <FlexItem direction="column" overflow="hidden">
                    <FlexRow alignCenter>
                        <IconButton icon="ArrowLeft" onClick={p.onBack} />
                        <HorizontalSpace base="16px" />
                        <H5>{chapterDetails.title}</H5>
                    </FlexRow>
                    <VerticalSpace base="16px" />
                    <FlexItem overflow="scroll" direction="column" yAlign="flex-start">
                        {(chapterDetails.subNodes?.npknode || []).map(division =>
                            (division.subNodes?.npknode || []).map(position => (
                                <Collapsable title={position.title} sideText={`${division.number.substring(0, 3)} ${division.title}`} key={position.number}>
                                    <Join items={position.subNodes?.npknode || []} renderJoining={() => <VerticalSpace base="8px" />}>
                                        {vwt => (
                                            <CatalogCollapsableItem
                                                isActive={vwt.number === p.selectedCatalogRef?.workTitleKey}
                                                onClick={() => p.onSelect(p.catalogId, vwt)}
                                                key={vwt.number}>
                                                {vwt.title}
                                            </CatalogCollapsableItem>
                                        )}
                                    </Join>
                                </Collapsable>
                            ))
                        )}
                    </FlexItem>
                </FlexItem>
            )
    }

})

type ShelfBrowserProps = Pick<CatalogSearchProps, "shelfType"> & {
    onCatalogSelect: F1<IdLite>
    setNPKChapters: F1<NPKCatalogs.Chapters>
    shelfVersion: Version
    licenseDetails: LicenseUnlockRequestPayload
    language: NPKCatalogs.TLanguage
}
const ShelfBrowser = asyncConnect({ stateResolvers: ["NPKChapters"], actions: ["fetchNPKChapters"] })<ShelfBrowserProps>(p => {

    React.useEffect(() => {
        if (p.NPKChapters.type === "NotFetched") {
            p.fetchNPKChapters({ ...p.licenseDetails, displayLanguage: p.language })

        }
    }, [p.NPKChapters])

    React.useEffect(() => {
        p.fetchNPKChapters({ ...p.licenseDetails, displayLanguage: p.language })
    }, [p.language])

    switch (p.NPKChapters.type) {
        case "NotFetched": return <></>
        case "Fetching": return <Spinner />
        case "Fetched":
            const chapters = p.NPKChapters.value;
            p.setNPKChapters(chapters);
            return (<Join items={chapters} renderJoining={() => <VerticalSpace base="16px" />}>
                {c => (
                    <CatalogCollapsableItem onClick={() => p.onCatalogSelect(c.number as IdLite)} key={c.number}>
                        {c.display}
                    </CatalogCollapsableItem>
                )}
            </Join>)
    }


})
