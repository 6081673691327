import * as React from "react"
import { useSelector, useDispatch } from "react-redux"
import { RootState, ClientActionsMap } from "../store"

import { ErrorView } from "../views/Error"
import { useStateActions, StateActionsProps } from "../hooks/useStateActions"
import {
    resolveAllDevis,
    resolveProjectDevisCollections,
    resolveDevis,
    resolveDevisCollections,
    resolveIsDevisReadonly,
    resolveIsDevisInSecondRound,
    resolveAllProjectDevis
} from "./devisResolver"
import {
    resolveProjectAttachments,
    resolveDevisTemplate,
    resolveDevisTemplates,
    resolveUserBasedWorkDescriptionTemplates,
    resolvePredefinedWorkDescriptionTemplates,
    resolveProjectDetails,
    resolveProjectsDetails,
    resolveProjectTemplatesDetails,
    resolveProjectPredefinedCollections,
    resolveDevisTemplateCollections,
    resolveUserDevisOverview,
    resolveTempProjectDetails
} from "./projectResolvers"
import { wrapAsync, resolveAsyncAppUser, resolveAppUser, mkResolver } from "./resolverUtils"
import {
    resolveArchitectContractors,
    resolveContractorOffer,
    resolveContractorOffers,
    resolveOptionalPublicOffer,
    resolvePublicOffer
} from "./contractorResolvers"
import { resolveAdminDevisOverview, resolveTranslations } from "./adminResolvers"
import { Dispatch } from "../utils/store"

import { Spinner } from "@smartdevis/ui/src/Spinner"
import {
    resolvePositionsDoc,
    resolveContractDoc,
    resolveOfferComparisonDoc,
    resolveSubmittersDoc,
    resolveProjectSubmittersDoc,
    resolveConditionsDoc,
    resolveNegotiationDoc,
    resolveDeductionsDoc,
    resolveContractorContractDoc,
    resolveContractPreviewDoc
} from "./docResolvers"
import { arrify, unique } from "@smartdevis/utils/src/array"
import { mkFetched, Async, WrappedAsync } from "@smartdevis/utils/src/async"
import { toMap, remap, keys } from "@smartdevis/utils/src/map"
import { identity } from "@smartdevis/utils/src/misc"
import { Arrayable, F1, F2, EmptyObject } from "@smartdevis/utils/src/types"
import { errors } from "@smartdevis/utils/src/validators"
import { Action } from "@smartdevis/utils/src/actions"
import { isEqual } from "lodash"
import { resolvePartners } from "./partnersResolvers"
import {
    resolveCatalog,
    resolveCatalogFull,
    resolveCatalogViewMeta,
    resolveCatalogWorkTitle,
    resolveCurrentShelf,
    resolveLatestPositionsShelf,
    resolveLatestConditionsShelf,
    resolveShelfTypeFromUrl,
    resolveLatestShelf
} from "./catalogResolvers"
import { resolveContractorsDirectory } from "./contractorsDirectoryResolvers"

const resolvers = {
    catalogMatches: mkResolver(s => s.catalog.matches),
    catalogWorkTitle: resolveCatalogWorkTitle,
    catalog: resolveCatalog,
    catalogFull: resolveCatalogFull,
    catalogMeta: resolveCatalogViewMeta,
    currentShelf: resolveCurrentShelf,
    latestShelf: resolveLatestShelf,
    latestPositionsShelf: resolveLatestPositionsShelf,
    latestConditionsShelf: resolveLatestConditionsShelf,
    shelfType: resolveShelfTypeFromUrl,
    results: mkResolver(s => mkFetched(s.cloud.results)),
    publishResults: mkResolver(s => mkFetched(s.catalog.publishResults)),
    auth: mkResolver(s => mkFetched(s.auth.auth)),


    CRBAccessAuth: mkResolver(s => mkFetched(s.crbNpk.CRBAccessAuth)),
    NPKChapters: mkResolver(s => mkFetched(s.crbNpk.NPKChapters)),
    SubscribedWorkstations: mkResolver(s => mkFetched(s.crbNpk.SubscribedWorkStations)),
    NPKUnits: mkResolver(s => mkFetched(s.crbNpk.NPKUnits)),
    NPKLanguages: mkResolver(s => mkFetched(s.crbNpk.NPKLanguages)),
    NPKVersionYears: mkResolver(s => mkFetched(s.crbNpk.NPKVersionYears)),
    NPKLicenseIds: mkResolver(s => mkFetched(s.crbNpk.NPKLicenseIds)),
    NPKChapterVersions: mkResolver(s => mkFetched(s.crbNpk.NPKChapterVersions)),
    NPKChapterDetails: mkResolver(s => mkFetched(s.crbNpk.NPKChapterDetails)),

    uploadingFiles: mkResolver(s => mkFetched(s.attachments.uploadingFiles)),
    appMeta: mkResolver(s => mkFetched(s.meta)),
    changelog: mkResolver(s => s.meta.changelog),
    asyncUser: resolveAsyncAppUser,
    user: resolveAppUser,
    language: mkResolver(s => mkFetched(s.meta.language)),
    projectsDetails: resolveProjectsDetails,
    tempProjectsDetails: resolveTempProjectDetails,
    projectTemplatesDetails: resolveProjectTemplatesDetails,

    api: mkResolver(s => mkFetched(s.api)),

    contractors: resolveArchitectContractors,
    partners: resolvePartners,
    devisCollections: resolveDevisCollections,
    projectDevisCollections: resolveProjectDevisCollections,
    projectPredefinedCollections: resolveProjectPredefinedCollections,
    projectDevis: resolveAllProjectDevis,
    allDevis: resolveAllDevis,
    projectAttachments: resolveProjectAttachments,
    devis: resolveDevis,
    devisTemplates: resolveDevisTemplates,
    userBasedWorkDescriptionTemplates: resolveUserBasedWorkDescriptionTemplates,
    predefinedWorkDescriptionTemplates: resolvePredefinedWorkDescriptionTemplates,
    devisTemplate: resolveDevisTemplate,
    devisTemplateCollections: resolveDevisTemplateCollections,
    projectDetails: resolveProjectDetails,
    isDevisReadonly: resolveIsDevisReadonly,
    isDevisInSecondRound: resolveIsDevisInSecondRound,
    devisOverview: resolveAdminDevisOverview,
    recentTenders: resolveUserDevisOverview,

    contractorsDirectory: resolveContractorsDirectory,

    positionsDoc: resolvePositionsDoc,
    contractDoc: resolveContractDoc,
    contractPreviewDoc: resolveContractPreviewDoc,
    contractorContractDoc: resolveContractorContractDoc,
    submittersDoc: resolveSubmittersDoc,
    projectSubmittersDoc: resolveProjectSubmittersDoc,
    conditionsDoc: resolveConditionsDoc,
    deductionsDoc: resolveDeductionsDoc,
    negotiationDoc: resolveNegotiationDoc,
    offerComparisonDoc: resolveOfferComparisonDoc,

    // contractor
    contractorOffers: resolveContractorOffers,
    contractorOffer: resolveContractorOffer,
    publicOffer: resolvePublicOffer,
    optionalPublicOffer: resolveOptionalPublicOffer,

    //admin
    translations: resolveTranslations
}
export type Resolvers = typeof resolvers
export type ResolverResult<R> = R extends Resolver<any, infer Res> ? Async<Res> : never
export type Resolver<Op, Res> = (state: RootState, ownProps: Op) => { result: Async<Res>; actions: Action[] }

type AsyncConnectParams<T extends keyof Resolvers, A extends keyof ClientActionsMap> = {
    stateResolvers?: Arrayable<T>
    actions?: Arrayable<A>
    renderLoading?: F1<any, React.ReactElement>
    renderError?: F2<string, any, React.ReactElement>
}

export type ResolversResults<T extends keyof Resolvers> = { [P in T]: ReturnType<Resolvers[P]> }
export type ResolversValues<T extends keyof Resolvers> = { [P in T]: ResolverResult<Resolvers[P]> }

let _cache: Partial<ResolversResults<keyof Resolvers>> = {}
export const useStateResolvers = <T extends keyof Resolvers>(resolverKeys: Arrayable<T>, ownProps: any) => {
    const dispatch = useDispatch()
    const delayedDispatch = (a => setTimeout(() => dispatch(a), 0)) as Dispatch
    const resolverResults = useSelector<RootState, ResolversResults<T>>(
        s =>
            toMap(arrify(resolverKeys), identity, resolverKey =>
                resolvers[resolverKey](s, ownProps)
            ) as ResolversResults<T>
    )

    const results = remap<ReturnType<Resolver<any, any>>>(resolverResults, identity, v => v.result)
    const actions = unique(
        keys(resolverResults)
            .map(k => {
                if (!_cache[k] || !isEqual(_cache[k], resolverResults[k]))
                    return resolverResults[k].actions.filter(
                        action => !_cache[k]?.actions.find(cachedAction => isEqual(action, cachedAction))
                    )
                return []
            })
            .flat()
    )
    actions.forEach(delayedDispatch)
    _cache = { ..._cache, ...resolverResults }
    return wrapAsync(results)
}

export const renderResolverLoading = <OwnProps extends any>(p: AsyncConnectParams<any, any>, op: OwnProps) =>
    p.renderLoading?.(op) || <Spinner />

export const renderResolverError = <OwnProps extends any>(
    p: AsyncConnectParams<any, any>,
    error: string,
    op: OwnProps
) => p.renderError?.(error, op) || <ErrorView type={error === errors.notFound ? "not-found" : "total"} text={error} />

export type AsyncConnectResults<R extends keyof Resolvers, A extends keyof ClientActionsMap = never> = WrappedAsync<
    ResolversValues<R>
> &
    StateActionsProps<A>

export const asyncConnect =
    <R extends keyof Resolvers = never, A extends keyof ClientActionsMap = never>(p: AsyncConnectParams<R, A>) =>
    <O extends any = EmptyObject>(
        Component: React.FC<WrappedAsync<ResolversValues<R>> & StateActionsProps<A> & O>
    ): React.FC<O> =>
    op => {
        const data = useStateResolvers(p.stateResolvers || [], op)
        const actions = useStateActions(p.actions || [])
        switch (data.type) {
            case "NotFetched":
            case "Fetching":
                return renderResolverLoading(p, op)
            case "FetchError":
                // eslint-disable-next-line no-console
                console.log("Error in async connect:", data.value, p.stateResolvers)
                return renderResolverError(p, data.value, op)
            case "Fetched":
                return <Component {...(data.value as WrappedAsync<ResolversValues<R>>)} {...actions} {...op} />
        }
    }
