import * as React from "react"
import { CatalogRef,NPKCatalogs } from "@smartdevis/server/src/domain"
import { GridContainer } from "@smartdevis/ui/src/utils/common"
import { i18n } from "../../../services/translations"
import { useClientWidth } from "../../../hooks/utilityHooks"
import { Modal } from "@smartdevis/ui/src/Modal"
import { useDebounce } from "@smartdevis/ui/src/hooks/useDebounce"
import { FormSchema, StyledFormSchema } from "@smartdevis/forms/src"
import { SMap } from "@smartdevis/utils/src/map"
import { isDefined } from "@smartdevis/utils/src/misc"
import { F1, F0, State } from "@smartdevis/utils/src/types"
import { IdLite } from "@smartdevis/utils/src/id"
import { ShelfType, Version } from "@smartdevis/server/src/domainCatalog"
import { NPKCatalogSearch } from "./crb-catalog-search"
import { CatalogItemsPreview } from "../devis-catalogs/devis-catalogs-preview"
import { LicenseUnlockRequestPayload } from "../../../store/crbNpkCatalogsState"
import { NPKCatalogDetailsForm } from "./crb-catalogs-details"

type CatalogFormAttributeType = "preset" | "custom"
type PickerHelpers<Item, FormPayload> = {
    onSubmit: F1<Item>
    onRemove: F1<Item>
    mkInitial: F1<Item, Partial<FormPayload>>
    attachCatalog: (i: Item, catalogId: IdLite, workTitle: NPKCatalogs.NPKNode) => Item
    mkNewItem: (i: Item) => Item
    mkCopyItem: (i: Item) => Item
    getItemId: (i: Item) => string
    renderItem: F1<Item, React.ReactNode>
    renderHeader: F0<React.ReactNode>
    mkSchema: (item: Item) => FormSchema<FormPayload>
    mkStyledSchema: F0<StyledFormSchema<FormPayload, CatalogFormAttributeType>>
}

type CatalogPickerMode =
    | State<"browseShelf", { force?: boolean }>
    | State<"browseCatalog", { catalogId: IdLite; force?: boolean }>

type CatalogItem = { catalogRef?: CatalogRef | null }
type CatalogPickerProps<FormPayload, Item extends CatalogItem> = PickerHelpers<Item, FormPayload> & {
    visible: boolean
    onClose: F0
    itemsToPreview: SMap<Item>
    previewTitle: string
    shelfType: ShelfType
    shelfVersion: Version
    item: Item
    licenseUnlockDetails: LicenseUnlockRequestPayload
}

export const NPKCatalogPicker = <FormPayload, Item extends CatalogItem>(p: CatalogPickerProps<FormPayload, Item>) => {
    const { item: initialItem, shelfVersion, shelfType } = p
    const [language, setLanguage] = React.useState<NPKCatalogs.TLanguage>('Deutsch')
    const [versionYear, setVersionYear] = React.useState<number>(2024)
    const [npkNode, setNPKNode] = React.useState<NPKCatalogs.NPKNode>(null)
    const [pickerMode, setPickerMode] = React.useState<CatalogPickerMode>(
        initialItem?.catalogRef
            ? { type: "browseCatalog", catalogId: initialItem.catalogRef.catalogId }
            : { type: "browseShelf" }
    )
    const [item, setItem] = React.useState<Item>(initialItem)
    const [noChanges, setNoChanges] = React.useState(true)

    const clientWidth = useClientWidth()

    const save = useDebounce((i: Item) => p.onSubmit(i), 1000)

    const close = () => {
        setPickerMode({ type: "browseShelf" })
        if (!noChanges) p.onSubmit(item)
        p.onClose()
    }

    const addToList = (i: Item) => {
        if (!i.catalogRef) return
        setNoChanges(true)
        p.onSubmit(i)
        setItem(p.mkCopyItem(i))
    }

    const addNewItem = () => {
        setNoChanges(true)
        setPickerMode({ type: "browseShelf" })
        setItem(p.mkNewItem(item))
    }

    const copyItem = () => {
        setNoChanges(true)
        setItem(p.mkCopyItem(item))
    }

    const update = (i: Item) => {
        setNoChanges(false)
        setItem(i)
        save.run(i)
    }

    const remove = (i: Item) => {
        if (p.getItemId(i) === p.getItemId(item)) addNewItem()
        p.onRemove(i)
    }

    const viewOtherItem = (i: Item) => {
        if (!i.catalogRef) return
        if (!noChanges) p.onSubmit(item)
        setPickerMode({ type: "browseCatalog", catalogId: i.catalogRef.catalogId })
        setItem(i)
        setNoChanges(true)
    }

    const selectPosition = (catalogId: IdLite, workTitle: NPKCatalogs.NPKNode) => {
        setNPKNode(workTitle);
        if (item.catalogRef) {
            p.onSubmit(item)
            setItem(p.attachCatalog(p.mkNewItem(item), catalogId, workTitle))
        } else {
            setItem(i => p.attachCatalog(i, catalogId, workTitle))
        }
        setNoChanges(false)
    }

    const isEditing = isDefined(item && p.itemsToPreview[p.getItemId(item)])
    const isScreenNarrow = clientWidth < 1280
    return (
        <Modal
            header={i18n("Select position from catalog")}
            size="xl"
            height="80vh"
            visible={p.visible}
            onClose={close}>
            <GridContainer columnsGrid={isScreenNarrow ? [2, 3] : [2, 2.5, 2]} gap="12px" height="100%">
                <NPKCatalogSearch
                    shelfType={shelfType}
                    shelfVersion={shelfVersion}
                    onSelect={selectPosition}
                    pickerMode={pickerMode}
                    setPickerMode={setPickerMode}
                    setLanguage={setLanguage}
                    setVersionYear={setVersionYear}
                    selectedCatalogRef={item.catalogRef}
                    licenseUnlockDetails={p.licenseUnlockDetails}
                    language={language}
                    versionYear={versionYear}
                    onBrowseShelf={() => setPickerMode({ type: "browseShelf", force: true })}
                    onBrowseCatalog={catalogId => setPickerMode({ type: "browseCatalog", catalogId, force: true })}
                />
                <NPKCatalogDetailsForm
                    {...p}
                    onAddToList={addToList}
                    onUpdate={update}
                    isEditing={isEditing}
                    onCopy={copyItem}
                    noChanges={noChanges}
                    catalogRef={item.catalogRef}
                    onBrowseCatalog={() =>
                        item.catalogRef
                            ? setPickerMode({ type: "browseCatalog", catalogId: item.catalogRef.catalogId })
                            : null
                    }
                    catalogId={item.catalogRef?.catalogId}
                    item={item}
                    catalogWorkTitle={npkNode}
                />
                {isScreenNarrow ? null : (
                    <CatalogItemsPreview
                        renderItem={p.renderItem}
                        renderHeader={p.renderHeader}
                        noChanges={noChanges}
                        isEditing={isEditing}
                        onAddNewItem={addNewItem}
                        onClose={close}
                        items={p.itemsToPreview}
                        getItemId={p.getItemId}
                        currentItem={item}
                        onRemove={remove}
                        onViewItem={viewOtherItem}
                        title={p.previewTitle}
                    />
                )}
            </GridContainer>
        </Modal>
    )
}
